import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [2];

export const dictionary = {
		"/": [~4],
		"/app": [~5,[2]],
		"/app/chats/[chatId]": [~6,[2]],
		"/app/settings": [~7,[2,3]],
		"/app/settings/account": [8,[2,3]],
		"/app/settings/customization": [~9,[2,3]],
		"/app/settings/teams": [~10,[2,3]],
		"/app/settings/teams/new": [12,[2,3]],
		"/app/settings/teams/[id]": [~11,[2,3]],
		"/forgot-password": [13],
		"/invitation/[hash]": [~14],
		"/reset-password/[token]": [~15],
		"/signin": [16],
		"/signout": [~17],
		"/signup": [18],
		"/verify/[token]": [~19]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';